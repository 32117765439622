import React from 'react';
import LottieAnimation from '../../../animation';
import map from '../../../animation/animations/animated-map.json';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
// import * as style from './index.module.scss';

export default function SignAnywhere() {
  const data: FeatureSectionProps = {
    items: [
      {
        title: 'Sign securely, anywhere, anytime.',
        description: (
          <>
            Say hello to a fully digital mortgage process. Apply online, upload documents from
            anywhere, anytime. Review documents with our team face to face (digitally) and sign your
            mortgage securely, all without leaving your home.
          </>
        ),
        art: signAnywhereArt(),
        actions: [{ label: `Get Started`, href: 'https://apply.nuborrow.com', isPrimary: true }]
      }
      // {
      //   title: 'We are just like you.',
      //   description: `When is the last time your bank text you back? Our team is just like you, busy and trying to fit everything into the day. So, go ahead - text, call or email us for help.`,
      //   art: null,
      //   actions: [{ label: `Go Ahead, Text Us`, href: '', isPrimary: true }]
      // }o
    ]
  };

  return <FeatureSection {...data} />;
}

const signAnywhereArt = () => {
  return (
    <div className={' d-flex flex-column align-items-center justify-content-center'}>
      <div className={''}>
        <LottieAnimation animation={map} threshold={0.2} className={''} />
      </div>
    </div>
  );
};
