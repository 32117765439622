import React, { ReactElement } from 'react';
import AnimatedCounter from '../animated-counter';
import { FeatureSectionItemAction } from '../feature-section/model';
import PerspectiveCard from '../perspective-card';
import RoundBtn from '../round-btn';
import SlideButton from '../slide-btn';
import { SlideButtonItem } from '../slide-btn/model';
import VisualDesc from '../visual-description';
import VisualTitle from '../visual-title';
import * as style from './index.module.scss';

export interface RateBannerProps {
  title: string;
  description: string | ReactElement;
  rate: number;

  slideButtonItems?: SlideButtonItem[];
  actionButtonItems?: FeatureSectionItemAction[];
}

export default function RateBanner(props: RateBannerProps) {
  return (
    <section className={style.rateBanner + ' d-block overflow-hidden position-relative m-auto'}>
      <div className="container position-relative h-100 d-flex flex-column align-items-center justify-content-center">
        <div
          className={
            style.rateContent +
            ' text-center d-flex flex-column align-items-center justify-content-center'
          }
        >
          <AnimatedCounter
            className={style.rate}
            value={props.rate}
            decimals={2}
            duration={1000}
            suffix={'%'}
          />
          <VisualTitle>{props.title}</VisualTitle>
          <VisualDesc>{props.description}</VisualDesc>
          {props.slideButtonItems && props.slideButtonItems.length && (
            <SlideButton
              className={`${style.slideBtn} justify-self-end`}
              items={props.slideButtonItems}
            />
          )}
          {props.actionButtonItems && (
            <div
              className={`${style.actions} d-flex align-self-stretch align-self-sm-center flex-column flex-lg-row`}
            >
              {props.actionButtonItems.map((action) => (
                <PerspectiveCard
                  key={action.label}
                  className={style.perspectiveCard}
                  intensity={25}
                >
                  <RoundBtn
                    key={action.label}
                    className={action.isPrimary ? style.primaryBtn : style.defaultBtn}
                    href={action.href}
                    isPrimary={action.isPrimary}
                  >
                    {action.label}
                  </RoundBtn>
                </PerspectiveCard>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
