import React from 'react';
import LottieAnimation from '../../../animation';
import chat from '../../../animation/animations/property-chat.json';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
import * as style from './index.module.scss';

export default function ShopForHome() {
  const data: FeatureSectionProps = {
    title: (
      <>
        Shop for rates at home <br /> (pants are optional)
      </>
    ),
    fullLengthTitle: true,
    items: [
      {
        title: 'Time is money.',
        description: (
          <>
            Who has time to make an appointment at the bank these days? Our Nuborrow team is 100%
            virtual and ready to meet you when you need us.
            <br />
            <br />
            Have a question? You can reach out at any time. Yes, even after 5PM and on weekends.
          </>
        ),
        art: timeIsMoneyArt(),
        actions: [{ label: `Get in Touch`, href: '/about/#contactus', isPrimary: true }]
      }
      // {
      //   title: 'We are just like you.',
      //   description: `When is the last time your bank text you back? Our team is just like you, busy and trying to fit everything into the day. So, go ahead - text, call or email us for help.`,
      //   art: null,
      //   actions: [{ label: `Go Ahead, Text Us`, href: '', isPrimary: true }]
      // },
      // {
      //   title: 'Sign anywhere, anytime.',
      //   description: `Hello, digital, goodbye paper. Apply online, upload documents from anywhere, anytime. Review documents with a Nuborrow team member and sign your mortgage all from the comfort of your home.`,
      //   art: null,
      //   actions: [{ label: `Get Started`, href: '', isPrimary: true }]
      // }
    ]
  };

  return <FeatureSection {...data} />;
}

const timeIsMoneyArt = () => {
  return (
    <div
      className={style.wrapper + ' d-flex flex-column align-items-center justify-content-center'}
    >
      {/* <ParallaxBackground className={style.heroImage} /> */}
      <div className={style.animationContainer}>
        <LottieAnimation animation={chat} threshold={0.2} className={''} />
      </div>
    </div>
  );
};
