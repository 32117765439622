import React from 'react';
import LottieAnimation from '../../../animation';
import chat from '../../../animation/animations/phone-chat.json';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
// import * as style from './index.module.scss';

export default function JustLikeYou() {
  const data: FeatureSectionProps = {
    items: [
      {
        title: 'We are just like you.',
        description: (
          <>
            When is the last time your bank manager texted you? Our Nuborrow team is just like you,
            busy and trying to fit everything into the day.
            <br />
            <br />
            So, go ahead - text, call or email us for help — anytime.
          </>
        ),
        art: justLikeYouArt(),
        actions: [{ label: `Go Ahead, Text Us`, href: '/about/#contactus', isPrimary: true }]
      }
    ]
  };

  return <FeatureSection {...data} />;
}

const justLikeYouArt = () => {
  return (
    <div className={' d-flex flex-column align-items-center justify-content-center'}>
      <div className={''}>
        <LottieAnimation animation={chat} threshold={0.2} className={''} />
      </div>
    </div>
  );
};
