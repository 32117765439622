import { motion } from 'framer-motion';
import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.scss';
import { SlideButtonProps } from './model';

const SlideButton = forwardRef<HTMLDivElement, StylableProp<SlideButtonProps>>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(props.selectedIndex ?? 0);
  const [selectedWidth, setSelectedWidth] = useState(0);
  const [selectedX, setSelectedX] = useState(0);
  const [resizeCount, setResizeCount] = useState(0);
  const btnRefs = props.items.map(() => useRef<HTMLButtonElement>(null));

  const onSelectedChanged = (index: number) => {
    const [width, x] = getSelectedAttrs(index);

    setSelectedWidth(width);
    setSelectedX(x);

    props.items[index].action?.();
  };

  const getSelectedAttrs = (index: number) => {
    const width = btnRefs[index].current?.clientWidth!;
    let x = 0;

    for (let i = 0; i < index; i++) {
      x += btnRefs[i].current?.clientWidth!;
    }

    return [width, x];
  };

  const handleResize = () => {
    setResizeCount(resizeCount + 1);
  };

  useLayoutEffect(() => {
    const [width, x] = getSelectedAttrs(selectedIndex);

    setSelectedWidth(width);
    setSelectedX(x);
  }, [resizeCount]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [resizeCount]);

  return (
    <div className={`${style.container} ${props.className}`}>
      <div
        className={style.slider}
        style={{
          ['--width' as string]: `${selectedWidth}px`,
          ['--trans-x' as string]: `${selectedX}px`
        }}
      ></div>
      <div className={style.buttons}>
        {props.items.map((item, index) => (
          <button
            key={item.label}
            ref={btnRefs[index]}
            className={index === selectedIndex ? style.active : ''}
            onClick={() => {
              onSelectedChanged(index);
              setSelectedIndex(index);
            }}
          >
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
});

export const MotionSlideButton = motion(SlideButton);
export default SlideButton;
