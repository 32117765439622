import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useBoolean, useInterval } from 'react-use';
import LogoCMLS from '../../images/optimized/logos_cmls_financial.svg';
import LogoFirstNational from '../../images/optimized/logos_first_national_financial.svg';
import LogoHomeTrust from '../../images/optimized/logos_home_trust.svg';
import LogoICICI from '../../images/optimized/logos_icici_bank.svg';
import LogoLaurentian from '../../images/optimized/logos_laurentian_bank.svg';
import LogoManulife from '../../images/optimized/logos_manulife_bank.svg';
import LogoScotiabank from '../../images/optimized/logos_scotiabank.svg';
import LogoTDBank from '../../images/optimized/logos_td_bank.svg';
import { StylableProp } from '../../utils/stylable-prop';
import ParallaxBackground from '../parallax-background';
import PerspectiveCard from '../perspective-card';
import RoundBtn from '../round-btn';
import SectionTitle from '../section-title';
import * as style from './index.module.scss';
import { CarouselProps } from './model';

export function Carousel(props: StylableProp<CarouselProps>) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [parentRef, inView] = useInView();
  const [isPaused, togglePaused] = useBoolean(true);

  const duration = 10 * 1000;

  const updateIndex = useCallback(() => {
    setActiveIndex((activeIndex + 1) % 3);
  }, [activeIndex]);

  useInterval(updateIndex, isPaused ? null : duration);

  useEffect(() => {
    setActiveIndex(0);

    if (inView) {
      togglePaused(false);
    }

    return () => {
      togglePaused(true);
    };
  }, [inView]);

  return (
    <section
      ref={parentRef}
      className={style.carousel + ` overflow-hidden position-relative m-auto`}
    >
      <div className="container position-relative h-100 d-flex flex-column">
        <div className={style.featuredItem + ' d-flex flex-column flex-xl-row w-100'}>
          <div
            className={`${style.titleContainer} d-flex my-4 my-xl-0 col-12 col-xl-4 flex-column align-items-center align-items-sm-start align-items-lg-center align-items-xl-start text-center text-sm-start`}
          >
            <SectionTitle compact={true}>
              Need loan <br className={'d-none d-xl-block'} />
              Options?
            </SectionTitle>
            <PerspectiveCard className={'col-12 col-sm-4 col-md-3 col-xl-6'} intensity={25}>
              <RoundBtn
                className={style.qualifiedBtn}
                isPrimary={true}
                href="https://apply.nuborrow.com"
              >
                Get Qualified
              </RoundBtn>
            </PerspectiveCard>
          </div>
          <div
            className={`${style.artContainer} d-none d-lg-block my-lg-4 my-xl-0 col-lg-12 col-xl-8`}
          >
            {props.items[activeIndex].artwork}
          </div>

          <div className={`${style.artContainer} d-none d-sm-block my-4 mb-0 d-lg-none`}>
            {props.items[0].artwork}
          </div>
        </div>
        <div className={style.allItems + ' d-flex flex-column flex-lg-row align-items-lg-start'}>
          {props.items?.map((item, index) => (
            <div
              key={`${index}-${item.title}`}
              className={`${style.item} ${activeIndex === index ? style.active : ''}`}
              onClick={() => {
                // console.log('click');
                togglePaused(true);
                setActiveIndex(index);

                setTimeout(() => togglePaused(false), 500);
              }}
            >
              <h3>{item.title}</h3>
              <div className={style.progress + ' d-none d-lg-block'}>
                <motion.div className={style.fill}></motion.div>
              </div>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export const propertyArt = () => {
  return (
    <div
      className={
        style.featuredProperty + ' d-flex justify-content-around justify-content-xl-between'
      }
    >
      <div className={style.propertyItem + ' d-flex flex-column align-items-center'}>
        <ParallaxBackground className={`${style.thumbnail} ${style.property1}`} />
        <div className={style.caption}>First-time Buyers</div>
      </div>

      <div className={style.propertyItem + ' d-flex flex-column align-items-center'}>
        <ParallaxBackground className={`${style.thumbnail} ${style.property2}`} />
        <div className={style.caption}>Secondary Home</div>
      </div>

      <div className={style.propertyItem + ' d-flex flex-column align-items-center'}>
        <ParallaxBackground className={`${style.thumbnail} ${style.property3}`} />
        <div className={style.caption}>Investment Property</div>
      </div>
    </div>
  );
};

export const doWeQualifyArt = () => {
  return (
    <div className={style.doWeQualify + ' d-flex align-items-center justify-content-around'}>
      <div className={style.pictureWrapper}>
        <ParallaxBackground className={style.qualifyPicture} />
      </div>

      <div className={style.qualifyInfo + ' d-flex flex-column'}>
        <h4>Just a few of our trusted partners</h4>
        <ul className="d-flex flex-wrap">
          <li className={'col-6'}>
            <img src={LogoScotiabank} />
          </li>
          <li className={'col-6'}>
            <img src={LogoTDBank} />
          </li>
          <li className={'col-6'}>
            <img src={LogoLaurentian} />
          </li>
          <li className={'col-6'}>
            <img src={LogoManulife} />
          </li>
          <li className={'col-6'}>
            <img src={LogoHomeTrust} />
          </li>
          <li className={'col-6'}>
            <img src={LogoICICI} />
          </li>
          <li className={'col-6'}>
            <img src={LogoCMLS} />
          </li>
          <li className={'col-6'}>
            <img src={LogoFirstNational} />
          </li>
        </ul>
      </div>
    </div>
  );
};

export const mortgageArt = () => {
  return (
    <div className={style.mortgage + ' d-flex align-items-center justify-content-around'}>
      <div className={style.pictureWrapper}>
        <ParallaxBackground className={style.mortgagePicture} />
      </div>

      <div className={style.mortgageInfo + ' d-flex flex-column'}>
        <h4>Unlimited mortgage options</h4>
        <ul className="d-flex flex-wrap">
          <li className={style.checkedItem + ' col-6'}>CMHC Insured</li>
          <li className={style.checkedItem + ' col-6'}>Uninsured purchase</li>
          <li className={style.checkedItem + ' col-6'}>High ratio program</li>
          <li className={style.checkedItem + ' col-6'}>Rental purchase</li>
          <li className={style.checkedItem + ' col-6'}>Vacation home</li>
          <li className={style.checkedItem + ' col-6'}>Self employed program</li>
          <li className={style.checkedItem + ' col-6'}>Fixed rate</li>
          <li className={style.checkedItem + ' col-6'}>Variable rate</li>
        </ul>
      </div>
    </div>
  );
};
