import React from 'react';
import { StylableProp } from '../../utils/stylable-prop';
import BasicCard from '../basic-card';
import * as style from './index.module.scss';

interface FeatureItem {
  highlight: string | JSX.Element;
  label: string;
}

export interface RateComparisonItem {
  logo: string | JSX.Element;
  primary: FeatureItem;
  secondary: FeatureItem;
}

export interface RateComparisonProps {
  title: string | JSX.Element;
  items: RateComparisonItem[];
  effectiveDate: Date;
}

export default function RateComparison(props: StylableProp<RateComparisonProps>) {
  return (
    <section
      className={
        style.rateComparison + ' position-relative m-auto d-flex flex-column justify-content-center'
      }
    >
      <div className="container">
        <h4 className={''}>{props.title}</h4>
        {props.items.map((item, index) => (
          <ComparisonItem key={'item-' + index} {...item} />
        ))}

        <div className={style.description + ' text-center mx-3'}>
          Rates effective {props.effectiveDate.toLocaleDateString()}. Conditions apply.
        </div>
      </div>
    </section>
  );
}

const ComparisonItem = (item: RateComparisonItem) => {
  return (
    <BasicCard className={style.rateCard}>
      <div className={style.cardContent + ' d-flex flex-column flex-sm-row align-items-sm-center'}>
        <div className={style.logo + ' mb-4 mb-sm-0 align-self-center'}>
          <img src={item.logo as string} />
        </div>
        <div className={style.details + ' d-flex align-items-center justify-content-center'}>
          <div className={`${style.rate}`}>
            <h4 className={style.highlight}>{item.primary.highlight}</h4>
            <div className={style.label}>{item.primary.label}</div>
          </div>
          <div className={`${style.rate}`}>
            <h4 className={style.highlight}>{item.secondary.highlight}</h4>
            <div className={style.label}>{item.secondary.label}</div>
          </div>
        </div>
      </div>
    </BasicCard>
  );
};
