import React, { useState } from 'react';
import CallToAction from '../components/call-to-action';
import { CallToActionButton, CallToActionCardProps } from '../components/call-to-action/models';
import { Carousel, doWeQualifyArt, mortgageArt, propertyArt } from '../components/carousel';
import { CarouselItem } from '../components/carousel/model';
import Layout from '../components/layout';
import RateBanner, { RateBannerProps } from '../components/rate-banner';
import RateComparison, {
  RateComparisonItem,
  RateComparisonProps
} from '../components/rate-comparison';
import JustLikeYou from '../components/sections/buy/just-like-you';
import ShopForHome from '../components/sections/buy/shop-for-home';
import SignAnywhere from '../components/sections/buy/sign-anywhere';
import CreditScore from '../components/sections/shared/credit-score';
import CMLSFinancial from '../images/optimized/logos_cmls_financial.svg';
import TDBank from '../images/optimized/logos_td_bank.svg';
import Nuborrow from '../images/nuborrow_logo.svg';
import { RootState } from '../utils/root-state';

export default function BuyPage() {
  const callToActionCards: CallToActionCardProps[] = [
    {
      title: 'Why waving conditions can be dangerous in any market.',
      actionLabel: 'Traps you must avoid',
      actionHref: ''
    },
    {
      title: 'How will the bank approve my application?',
      actionLabel: 'Why location matters',
      actionHref: ''
    },
    {
      title: 'Does a pre-approval mean we are approved?',
      actionLabel: 'Avoid stressful delays',
      actionHref: ''
    },
    {
      title: 'Do we really need all of these documents?',
      actionLabel: 'Documents you need',
      actionHref: ''
    },
    {
      title: 'Match me with an agent.',
      actionLabel: 'Real Estate',
      actionHref: 'https://apply.nuborrow.com'
    },
    {
      title: 'What should I do?',
      actionLabel: 'Get home advice',
      actionHref: '/learn'
    }
  ];

  const ctaTitle = (
    <>
      You've found your <br /> home, now buy it
    </>
  );

  const ctaButtons: CallToActionButton[] = [
    {
      label: 'Get Started',
      href: 'https://apply.nuborrow.com',
      isPrimary: true
    }
  ];

  const [rate, setRate] = useState(0);
  const [effectiveDate, setEffectiveDate] = useState(new Date(1970, 0));

  const rateProps: RateBannerProps = {
    title: 'Lower rates. Zero fees.',
    rate,
    description: (
      <>
        <span>
          Truth is, shopping for a new home is fun, but when it comes to mortgages, it can be
          frustrating.
        </span>
        <br className={'d-none d-lg-block'} />
        &nbsp;
        <span>
          Let Nuborrow do all the heavy lifting, negotiating, and rate shopping, while you find the
          perfect home.
        </span>
      </>
    ),
    // slideButtonItems: [
    //   {
    //     label: `Today's Rate`,
    //     action: () => setRate(1.89)
    //   },
    //   {
    //     label: `View APR`,
    //     action: () => setRate(3.1415)
    //   }
    // ],
    actionButtonItems: [
      {
        label: 'Get Started',
        href: 'https://products.nuborrow.com/#purchases_basic_detail',
        isPrimary: true
      }
    ]
  };

  const [rateComparisonItems, setRateComparisonItems] = useState<RateComparisonItem[]>([
    {
      logo: Nuborrow,
      primary: {
        highlight: '1.44%',
        label: 'Variable Rate'
      },
      secondary: {
        highlight: '2.04%',
        label: 'Fixed Rate'
      }
    },
    {
      logo: TDBank,
      primary: {
        highlight: '1.44%',
        label: 'Variable Rate'
      },
      secondary: {
        highlight: '2.04%',
        label: 'Fixed Rate'
      }
    },
    {
      logo: CMLSFinancial,
      primary: {
        highlight: '1.44%',
        label: 'Variable Rate'
      },
      secondary: {
        highlight: '2.04%',
        label: 'Fixed Rate'
      }
    }
  ]);

  const rateComparison: RateComparisonProps = {
    title: `Today's best 5 years variable and fixed rates.`,
    items: rateComparisonItems,
    effectiveDate: effectiveDate
  };

  const carouselItems: CarouselItem[] = [
    {
      title: 'Property',
      description: `Finding the perfect home can be a stressful process, but what happens after you've found the "one"?`,
      artwork: propertyArt()
    },
    {
      title: 'Do We Qualify?',
      description: `Moving into your dream home takes work. Let our team help you qualify, answer your questions, and ultimately find the perfect mortgage.`,
      artwork: doWeQualifyArt()
    },
    {
      title: 'Mortgage Options',
      description: `The lowest rate is always the goal, but it may not be the best option. Let our team walk you through the programs you qualify for and help you decide.`,
      artwork: mortgageArt()
    }
  ];

  const handleState = (state: RootState) => {
    setRate(state.findLowestRateForProduct('Buying')?.variable_rate ?? 0);

    if (!state.productRates.Buying) {
      return;
    }

    const list = state.findLowestRatesForProduct('Buying', 3);
    const items: RateComparisonItem[] = list.map((bank) => ({
      logo: state.getLogoForBank(bank.name),
      primary: {
        highlight: `${bank.variable_rate}%`,
        label: 'Variable Rate'
      },
      secondary: {
        highlight: `${bank.rate}%`,
        label: 'Fixed Rate'
      }
    }));

    setEffectiveDate(new Date(state.productRates.Buying.effectiveDate));
    setRateComparisonItems(items);
  };

  return (
    <Layout pageTitle={'Buy'} onRootState={handleState}>
      <RateBanner {...rateProps} />
      {/* <FeatureComparison items={featureComparisonItems} /> */}
      <RateComparison {...rateComparison} />
      <Carousel items={carouselItems} />
      <ShopForHome />
      <JustLikeYou />
      <SignAnywhere />
      <CreditScore />
      {/* <FindSomething /> */}
      <CallToAction title={ctaTitle} cards={callToActionCards} actions={ctaButtons} />
    </Layout>
  );
}
